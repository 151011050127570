<template>
    <tr v-show="!item.isEdit" @click="openEditLine(index)">
        <td class="pl-10">
            <div class="col">
                <strong>
                    <h6>{{ item.name }}</h6>
                </strong>
                {{ item.description }}
            </div>
        </td>
        <td class="text-center">
            {{ item.stock_in_hand }}
            <span v-if="item.description">
                {{ item.description }}
            </span>
        </td>
        <td class="text-center">{{ item.quantity }}</td>
        <td class="text-center">{{ item.rate }}</td>
        <td class="text-center">{{ item.amount }}</td>
        <td class="text-center">{{ item.discount_percent }}%</td>
        <td class="text-center">{{ item.discount_amount }}</td>
        <td class="text-center">{{ amountAfterDiscount }}</td>
        <td class="text-center">{{ item.vat }}%</td>
        <td class="text-center">{{ item.vat_amount }}</td>
        <td class="text-center">{{ totalAmount }}</td>
        <td v-if="! fromReturn">
            <div>
                <button @click="deleteItem(index)" class="btn btn-sm btn-dlt">
                    <i class="fas fa-trash"></i>
                </button>
            </div>
        </td>
    </tr>

    <tr v-show="item.isEdit" :class="item.isEdit && 'tr-box-shadow'">
        <td>
            <div class="td-inline" v-if="! fromReturn">
                <AsyncSelect
                    placeholder="Select Product"
                    v-model="productId"
                    label="text"
                    :api-service="fetchProductWithBatch"
                    :reduce="text => text.stock_id"
                    :format-label="option => option.text"
                    :additional-option="additionalOptions"
                    :additional-query="{ location_id: formData.location_id }"
                    ref="productField"
                />
            </div>

            <div class="col" v-else>
              <strong>
                <h6>{{ item.name }}</h6>
              </strong>
              {{ item.description }}
            </div>
        </td>
        <td>
            <div class="td-inline">
                {{ item.stock_in_hand }}
                <span v-if="item.description">
                    {{ item.description }}
                </span>
            </div>
        </td>
        <td>
            <div class="td-inline">
                <input
                    v-model.trim="item.quantity"
                    type="number" class="form-control"
                    placeholder="Qty" :min="0"
                    :disabled="fromReturn"
                >
            </div>
        </td>
        <td>
            <div class="td-inline">
                <input
                    v-model.trim="item.rate"
                    type="number" class="form-control"
                    placeholder="Rate" :min="0"
                    :disabled="fromReturn"
                >
            </div>
        </td>
        <td>
            <div class="td-inline">
                <vField
                    readonly
                    v-model="amount"
                    name="amount text-right"
                    type="number"
                    class="form-control text-right"
                    :disabled="fromReturn"
                />
            </div>
        </td>
        <td>
            <div class="td-inline">
                <input
                    v-model.trim="item.discount_percent"
                    type="number" class="form-control"
                    placeholder="Discount(%)" :min="0"
                    :disabled="fromReturn"
                >
            </div>
        </td>
        <td>
            <div class="td-inline">
                <vField
                    v-model="item.discount_amount"
                    name="discount-amount"
                    type="number"
                    class="form-control text-right"
                    min="0"
                    :disabled="fromReturn"
                />
            </div>
        </td>
        <td>
            <div class="td-inline">
                <vField
                    v-model="amountAfterDiscount"
                    name="amount"
                    type="number"
                    class="form-control text-right"
                    :disabled="fromReturn"
                />
            </div>
        </td>
        <td>
            <div class="td-inline">
                <vField
                    v-model="item.vat"
                    id="vat"
                    as="select"
                    name="vat"
                    class="form-select"
                    :disabled="fromReturn"
                >
                    <option
                        v-for="(vat, i) in vatRate"
                        :value="vat.value"
                        :key="i"
                    >
                        {{vat.label}}
                    </option>
                </vField>
            </div>
        </td>
        <td>
            <div class="td-inline">
                <vField
                    v-model="item.vat_amount"
                    name="vat_amount"
                    type="number"
                    class="form-control text-right"
                    :disabled="fromReturn"
                />
            </div>
        </td>
        <td>
            <div class="td-inline">
                <vField
                    v-model="totalAmount"
                    readonly
                    name="total_amount"
                    type="number"
                    class="form-control text-right"
                    :disabled="fromReturn"
                />
            </div>
        </td>
        <td  v-if="! fromReturn">
            <div>
                <button @click="deleteItem(index)" class="btn btn-sm btn-dlt">
                    <i class="fas fa-trash"></i>
                </button>
            </div>
        </td>
    </tr>

</template>
<script setup>
import {computed, inject, ref, watch} from "vue";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handlePurchase from "@/services/modules/purchase";
import {useRoute} from "vue-router";

    const props = defineProps({
      item: Object,
      index: Number,
      onOpenEditItem: Function,
      onDeleteItem: Function,
      vatRate: Number,
      fromReturn: {
        type: Boolean,
        default: false
      }

    })
    const emit = defineEmits(['onOpenEditItem', 'onDeleteItem']);
    const $route = useRoute();
    const showError = inject('showError')
    const formData = inject('formData')
    const companyId = $route.params.companyId
    const companyQuery = `?company_id=${companyId}`;
    const { fetchProductWithBatch, fetchProductByStockId } = handlePurchase();
    const openEditLine = (i) => {
        emit('onOpenEditItem', i)
    }

    const deleteItem = (i) => {
        emit('onDeleteItem', i)
    }
    const amount = computed(() => props.item.amount = (props.item.rate * props.item.quantity).toFixed(4))

    const discountPercent = computed(() => props.item.discount_percent);

    const amountAfterDiscount = computed(() => {
        const discountAmount = props.item.discount_amount === '' ? 0 : parseFloat(props.item.discount_amount)
        if(discountAmount === 0) {
          return parseFloat(amount.value).toFixed(4);
        }
        return (amount.value - discountAmount).toFixed(4)
    })

    const vatAmount = computed(() => {
        if(props.item.vat === 0) {
            return 0
        }

        if(props.item.vat) {
            return (props.item.vat/100 * amountAfterDiscount.value).toFixed(4)
        }
        return (props.item.vat/100 * amountAfterDiscount.value).toFixed(4);
    })

    const productId = ref(null)


    const totalAmount = computed(() => {
        if(props.item.vat_amount === 0) {
            return props.item.total_amount = amountAfterDiscount.value
        }
        return props.item.total_amount = (parseFloat(amountAfterDiscount.value) + parseFloat(props.item.vat_amount)).toFixed(4)
    })


    watch(vatAmount, (newValue, oldValue) => {
        if(! isNaN(oldValue) || !$route.params.invoiceId){
            props.item.vat_amount  = newValue;
        }
    })

    watch(productId, async () => {
        if (!productId.value) {
            return;
        }

        const productResp = await fetchProductByStockId(productId.value, companyQuery);

        if (!productResp.data) {
            showError("Product not found");
            return;
        }

        const selected = productResp.data;
        props.item.product_id = selected.id;
        props.item.stock_register_id = selected.stock_id;
        props.item.rate = selected.sales_price;
        props.item.amount = props.item.quantity * props.item.rate;
        props.item.name = selected.name;
        props.item.option_name = selected.text;
        props.item.vat = selected.vat_rate;
        props.item.stock_in_hand = selected.stock_in_hand;
        props.item.description = selected.description;
        props.item.product = {
            name: selected.name
        };
    })

    watch(discountPercent, (value) => {
        let discount = 0;
        value = parseFloat(value);
        if(value && value > 0 && amount.value > 0) {
          discount = amount.value * (value/100);
        }
        props.item.discount_percent = value;
        props.item.discount_amount = discount.toFixed(4);
    })

    const additionalOptions = computed(() => {
        if (props.item.product_id) {
            return [
                {
                    stock_id: props.item.product_id,
                    text: props.item.option_name,
                }
            ]
        }
    });

</script>
<style scoped>
.btn-dlt{
    color: #ea5455;
}
</style>